<template>
  <div class="logout--inner"></div>
</template>

<script>
import axios from 'axios'
import { mapMutations } from 'vuex'

//= mixins
import { vueLocalStorage } from '@/assets/js/mixins/base/VueLocalStorage'
import DetectDeviceV2 from '@/assets/js/mixins/base/DetectDeviceV2'

export default {
  name: 'Logout',

  mixins: [
    DetectDeviceV2
  ],

  async created () {
    await this.$store.dispatch('logins/logout', {
      device: this.$_device.type,
      browser: this.$_device.browser,
      os: this.$_device.os,
      host: window.location.host
    })

    vueLocalStorage.removeItem('token')
    vueLocalStorage.removeItem('loginid')
    vueLocalStorage.removeItem('loginname')
    vueLocalStorage.removeItem('agentLang')
    vueLocalStorage.removeItem('permission')
    vueLocalStorage.removeItem('level')

    this.SET_USER('')

    axios.defaults.headers.common['Authorization'] = ''

    this.$router.push('/login')
  },

  methods: {
    ...mapMutations('logins', ['SET_USER'])
  }
}
</script>
